import { ModalRef } from './modal-ref.model';


export abstract class Modal {
  modalInstance: ModalRef;

  abstract onInjectInputs(inputs: any): void;

  close(output?: { [p: string]: number | string | boolean }) {
    this.modalInstance.close(output);
  }

  dismiss(output?: { [p: string]: number | string | boolean }) {
    this.modalInstance.dismiss(output);
  }
}
